import React from 'react'
import * as styles from '../../assets/styles.module.scss'
import { default as ContactForm } from './ContactForm'

interface Props {
    title?: string
    subtitle?: string
}

interface State {
    title?: string
    subtitle?: string
}

export default class Contact extends React.Component<Props, State> {

    constructor(props: any) {
        super(props)

        this.state = {
            title: this.props.title || `Let's build something great`,
            subtitle: this.props.subtitle || `Need help with rapid prototyping, UX design, app development, 
            or digital product strategy? Please fill out the form below 
            for a free consultation. All fields required.`,
        }
    }

    render() {
        const { title, subtitle } = this.state

        return (
            <section id="Contact" className={styles.Contact}>
                <div className={styles.Centered}>
                    <header>
                        <h1>{title}</h1>

                        <p className={styles.Subtitle}>{subtitle}</p>
                    </header>

                    <ContactForm />
                </div>
            </section>
        )
    }
}