import React from 'react'
import * as styles from '../../assets/styles.module.scss'

export default () => (
    <section className={styles.Tagline}>
        <div className={styles.Centered}>
            <div className={styles.Block}>
                <h1>
                    We believe that the customer is the most
                    important part of a product – building for
                    them is what makes us happy.
                </h1>
            </div>
        </div>
    </section>
)