import * as React from 'react'
import axios from 'axios'
import * as styles from '../../assets/styles.module.scss'

import {
    Row,
    Col,
    Form,
    Button
} from 'react-bootstrap'

interface Props {
    name?: String
    email?: String
    phone?: String
    jobTitle?: String
    companyName?: String
    companySize?: String
    message?: String
    successMessage?: boolean
    errorMessage?: boolean
    emptyMessage?: boolean
    wrongEmail?: boolean
    language?: string
}

interface State {
    name?: String
    email?: String
    phone?: String
    message?: String
    jobTitle?: String
    companyName?: String
    companySize?: String
    successMessage?: boolean
    errorMessage?: boolean
    emptyMessage?: boolean
    wrongEmail?: boolean
    language?: string
    messages: any
}

const _MESSAGES = [
    {
        send: 'Send Message',
        challenge: 'Tell us about the challenge'
    },
    {
        send: 'Enviar Mensaje',
        challenge: 'Cuéntanos sobre el proyecto'
    },
]

export default class ContactForm extends React.Component<Props, State> {

    constructor(props: any) {
        super(props)

        this.state = {
            name: this.props.name,
            email: this.props.email,
            phone: this.props.phone,
            message: this.props.message,
            jobTitle: this.props.jobTitle,
            companyName: this.props.companyName,
            companySize: this.props.companySize,
            successMessage: this.props.successMessage,
            errorMessage: this.props.errorMessage,
            emptyMessage: this.props.emptyMessage,
            wrongEmail: this.props.wrongEmail,
            language: this.props.language ? this.props.language : 'en',
            messages: _MESSAGES[0]
        }

        if (this.state.language === 'en') {
            this.setState({
                messages: _MESSAGES[0]
            })
        }

        if (this.state.language === 'es') {
            this.setState({
                messages: _MESSAGES[1]
            })
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e: Event) {

        e.preventDefault()

        if (!this.validateForm()) {
            this.setState({emptyMessage: true})
            this.refs.name.focus()

            return
        }
        
        const {
            name,
            email,
            jobTitle,
            companyName,
            companySize,
            phone,
            message
        } = this.state

        const url = 'https://api.juvasoft.com/api/juvasoft/contacto'
        const params = {
            name,
            email,
            phone,
            jobTitle,
            companyName,
            companySize,
            message
        }

        axios.post(url, params)
         .then((result: any) => {
            this.refs.name.value = ''
            this.refs.email.value = ''
            this.refs.phone.value = ''
            this.refs.jobTitle.value = ''
            this.refs.companyName.value = ''
            this.refs.companySize.value = ''
            this.refs.message.value = ''

            this.refs.name.focus()

            this.setState({successMessage: true})
         }).catch((error) => {
             console.log(error)
             this.setState({errorMessage: true})
         })
    }

    onChangeEmail(event: Event) {
        this.setState({
            email: event.target.value,
            wrongEmail: false
        })

        if (!this.validateEmail(event.target.value)) {
            this.setState({wrongEmail: true})
        }
    }

    validateForm() {
        if (this.state.name === undefined) { return false }
        if (this.state.phone === undefined) { return false }
        if (this.state.jobTitle === undefined) { return false }
        if (this.state.companyName === undefined) { return false }
        if (this.state.companySize === undefined) { return false }
        if (this.state.email === undefined) { return false }
        if (this.state.message === undefined) { return false }

        return true
    }

    validateEmail(element: string) {

        // tslint:disable-next-line:max-line-length
        var emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    
        if (emailRegex.test(element)) { return true }
    
        return false
    }

    renderSuccessMessage() {
        return (
            <div className={styles.ContactSuccess}>
                Thank you! We'll get in touch soon.
            </div>
        )
    }

    renderErrorMessage() {
        return (
            <div className={styles.ContactError}>
                We had a problem sending the message, do youi want to try it again later?
            </div>
        )
    }

    renderEmptyMessage() {
        return (
            <div className={styles.ContactEmpty}>
                Don't forget to fill out the fields.
            </div>
        )
    }

    renderWrongEmailMessage() {
        return (
            <div className={styles.ContactEmpty}>
                Oh no! We need a valid email from you
            </div>
        )
    }

    render() {
        const renderSuccessMessage = (this.state.successMessage && this.renderSuccessMessage())
        const renderErrorMessage = (this.state.errorMessage && this.renderErrorMessage())
        const renderEmptyMessage = (this.state.emptyMessage && this.renderEmptyMessage())
        const renderWrongEmailMessage = (this.state.wrongEmail && this.renderWrongEmailMessage())
        const { messages } = this.state

        return (
            <div className={styles.ContactForm} onSubmit={ this.handleSubmit }>
                <form name="contact" method="post">
                    <input type="hidden" name="bot-field" />
                    <Row>
                        <Col xs={ 12 } md={ 6 }>
                            <Form.Group>
                                <Form.Label>Full Name</Form.Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref="name"
                                    onChange={event => this.setState({name: event.target.value})}
                                    placeholder="Arya Stark" />
                            </Form.Group>
                        </Col>
                        <Col xs={ 12 } md={ 6 }>
                            <Form.Group>
                                <Form.Label>E-Mail</Form.Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref="email"
                                    onBlur={event => this.onChangeEmail(event)}
                                    placeholder="arya@starkindustries.com" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={ 12 } md={ 6 }>
                            <Form.Group>
                                <Form.Label>Phone Number</Form.Label>
                                <input
                                    className="form-control"
                                    type="tel"
                                    ref="phone"
                                    onChange={event => this.setState({phone: event.target.value})}
                                    placeholder="2062021108" />
                            </Form.Group>
                        </Col>
                        <Col xs={ 12 } md={ 6 }>
                            <Form.Group>
                                <Form.Label>Job Title</Form.Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref="jobTitle"
                                    onChange={event => this.setState({jobTitle: event.target.value})}
                                    placeholder="Chief Executive Officer" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={ 12 } md={ 6 }>
                            <Form.Group>
                                <Form.Label>Company Name</Form.Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref="companyName"
                                    onChange={event => this.setState({companyName: event.target.value})}
                                    placeholder="Stark Industries" />
                            </Form.Group>
                        </Col>
                        <Col xs={ 12 } md={ 6 }>
                            <Form.Group>
                                <Form.Label>Company Size</Form.Label>
                                    <select id="company_size" 
                                        name="company_size"
                                        ref="companySize"
                                        className="form-control"
                                        onChange={event => this.setState({companySize: event.target.value})}>
                                        <option value="249">1-249 employees</option>
                                        <option value="999">250-999 employees</option>
                                        <option value="4999">1,000-4,999 employees</option>
                                        <option value="5000">5,000+ employees</option>
                                    </select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={ 12 } md={ 12 }>
                            <Form.Group>
                                <Form.Label>{messages.challenge}</Form.Label>
                                <textarea 
                                    className="form-control"
                                    ref="message"
                                    onChange={e => this.setState({message: e.target.value})}
                                    placeholder=""
                                    rows={5}
                                ></textarea>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Button type="submit" variant="success" block>{messages.send}</Button>
                        </Col>
                    </Row>
                </form>
                {renderSuccessMessage}
                {renderErrorMessage}
                {renderEmptyMessage}
                {renderWrongEmailMessage}
            </div>
        )
    }
}
