import * as React from 'react'
import * as styles from '../../assets/styles.module.scss'
import Heart from '../../assets/vectors/heart.svg'

export default class Navbar extends React.Component<{}, {}> {
    render() {
        return (
            <footer className={styles.Footer}>
                <div className={styles.Copyright}>
                    <header>
                        <h2>Do you want to talk with a human?</h2>
                    </header>
                    <span>Juvasoft Soluciones S.C.</span> | 
                    <span>P: <a href="tel:+12062021108">+1 (206) 202-1108</a></span> | 
                    <span>P: <a href="tel:+523310320163">+52 (331) 032-0163</a></span> | 
                    <span>E: <a href="mailto: hello@juvasoft.com">hello@juvasoft.com</a></span>
                </div>
                <div className={styles.BrewedWithLove}>
                    <p>Brewed with <Heart /> in Sinaloa, México</p>
                </div>
            </footer>
        )
    }
}