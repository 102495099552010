import * as React from 'react'
import * as styles from '../../assets/styles.module.scss'
import { Helmet } from 'react-helmet'
import Juvasoft from '../../assets/vectors/juvasoft.svg'

interface Props {
    link?: string
    title?: string
    description?: string
    coverImage?: string
    landing?: boolean
}

interface State {
    link?: string
    title?: string
    description?: string
    coverImage?: string
    landing?: boolean
}

export default class Header extends React.Component<Props, State> {

    constructor(props: any) {
        super(props)

        console.log(this.props.landing)

        this.state = {
            link: this.props.link || 'https://juvasoft.com',
            title: this.props.title || 'Juvasoft | Technology Consulting Firm',
            description: this.props.description || 
                `Juvasoft is a Product Strategy and Development Firm. 
                We focus on consistently solving business problems 
                through the application of technology.`,
            coverImage: this.props.coverImage || 'https://juvasoft.com/static/rocket-1f9c3998a82cdfcc51b8b60abf167830.jpg',
            landing: this.props.landing ? true : false
        }
    }

    render() {
        const { title, description, link, coverImage, landing } = this.state

        return (
            <header className={styles.Header}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{title}</title>
                    <link rel="canonical" href={link} />
                    <meta name="author" content="Juvasoft Soluciones S.C." />
                    <link rel="icon" type="image/png" href="../../assets/images/favicon.ico" sizes="16x16" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@juvasoft" />
                    <meta name="twitter:creator" content="@juvasoft" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image:src" content={coverImage} />

                    <meta property="og:title" content={title} />
                    <meta property="og:site_name" content="Juvasoft Soluciones S.C."/>
                    <meta property="og:url" content={link} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={coverImage} />
                </Helmet>
                <div className={styles.Centered}>
                    <div className={styles.Logo}>
                        <a href="/">
                            <Juvasoft />
                        </a>
                    </div>

                    {!landing && (
                        <nav className={styles.Navigation}>
                            <a href="/about">About</a>
                            <a href="/work">Work</a>
                            <a href="/insights">Insights</a>
                            <a href="/contact">Contact</a>
                        </nav>
                    )}
                </div>
            </header>
        )
    }
}