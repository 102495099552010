import * as React from 'react'
import * as styles from '../assets/styles.module.scss'
import { default as Header } from './common/header'
import { default as Footer } from './common/footer'

import { default as Tagline } from './homepage/Tagline'
import { default as Contact } from './homepage/Contact'

export default class Page extends React.Component<{}, {}> {
    public render() {
        return (
            <section className={styles.Container}>
                <Header title="Contact Us | Juvasoft" />
                <div className={styles.Homepage}>
                    <Tagline />
                    <Contact />
                </div>
                <Footer />
            </section>
        )
    }
}